import React from 'react';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';

import { useTrackedState } from '../../lib/state';

import SubLayout from '../../components/SubLayout';
import { P } from '../../components/common/P.style';
import { Img } from '../../components/common/Img.style';
import { H3, H4 } from '../../components/common/H.style';
import Card from '../../components/Card';
import ListItem from '../../components/ListItem';

import panc from '../../assets/img/panc.png';
import timer from '../../assets/img/timer.png';
import echoPanc from '../../assets/img/echo-panc.png';
import blood from '../../assets/img/blood.png';
import mrcp from '../../assets/img/mrcp.png';

const Bold = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #3b4043;
`;
const MenuName = styled(Bold)``;
const MenuPrice = styled(Bold)``;
const MenuContentTitle = styled.span`
  font-size: 14px;
  color: #3b4043;
`;
const MenuContent = styled(MenuContentTitle)`
  font-size: 12px;
`;

const MenuBox = ({ title, price, contents, size }) => (
  <Flex
    center
    css={`
      margin: 20px 0;
    `}
  >
    <Card width={(size !== 'sm' && '660px') || 'inherit'}>
      <Flex
        column
        css={`
          margin: ${(size !== 'sm' && '10px 20px') || '0'};
        `}
      >
        <Flex justifyBetween wrap="true">
          <MenuName>{title}</MenuName>
          <MenuPrice>{price}</MenuPrice>
        </Flex>
        <Flex column>
          <MenuContentTitle>検査内容</MenuContentTitle>
          <MenuContent>{contents}</MenuContent>
        </Flex>
      </Flex>
    </Card>
  </Flex>
);

const Exam = ({ title, imgSrc, content, size }) => (
  <Flex column>
    <FlexItem
      css={`
        width: ${(size === 'lg' && '325px') || 'inherit'};
      `}
    >
      <H4>{title}</H4>
      <FlexItem
        css={`
          margin: 0 20px;
        `}
      >
        <Img
          src={imgSrc}
          css={`
            margin-top: 10px;
          `}
        />
        <P>{content}</P>
      </FlexItem>
    </FlexItem>
  </Flex>
);

const PancreasExamination = () => {
  const { size } = useTrackedState();
  return (
    <SubLayout topTitle="膵臓ドックのご案内">
      <Img src={panc} />
      <Flex
        center
        css={`
          margin: 20px 0;
        `}
      >
        <Card width={(size !== 'sm' && '600px') || 'inherit'}>
          <Flex column>
            <ListItem label="ご家族に膵臓病の方がいるので膵臓が心配" />
            <ListItem label="油ものに弱い" />
            <ListItem label="お酒を飲むと下痢する" />
            <ListItem label="左腰が痛い" />
            <ListItem label="左上腹部が痛い" />
            <ListItem label="空腹時に冷や汗が出たり、 居ても立っても居られない気分になることがある" />
          </Flex>
        </Card>
      </Flex>
      <P>
        そんな不安はありませんか？
        しっかり調べるのであれば、特に膵臓をじっくり丁寧に見る腹部超音波（エコー）検査、リパーゼ、トリプシン、腫瘍マーカーを含めた血液検査、MRCP（膵臓・胆道系に特化したMRIの撮影法）をお勧めします。
      </P>
      <H3>料金</H3>
      <MenuBox
        size={size}
        title="膵臓ドック単体"
        price="65,900円（税込）"
        contents="MRCP、腹部エコー、血清アミラーゼ、P型アミラーゼ、リパーゼ、トリプシン、エラスターゼ1、CEA、CA19-9、尿アミラーゼ"
      />
      <MenuBox
        size={size}
        title="膵臓ドック＋人間ドック"
        price="94,100円（税込）"
        contents="膵臓ドック（上記）＋身体計測、尿検査、便検査、血液検査、心電図、レントゲン、腹部超音波検査、肺機能検査、眼底・色覚検査"
      />
      <Flex center column>
        <FlexItem>
          <Img src={timer} />
        </FlexItem>
        <FlexItem>
          <P>
            検査は１日で終了。
            <br />
            平日夜でも、土曜でも受けられます。
            <br />
            結果は自宅に郵送されます。
            <br />
            腹部超音波検査の結果は当日医師からご説明いたします。
            <br />
            MRCPの結果は後日となりますが、ご希望の方は当日にお聞きいただくことも可能です。
            <br />
            ※検査が夜間の場合には当日に結果をお聞きいただくことはできませんのでご了承ください。
            <br />
            ※当日に結果をお聞きになる場合は、2時間程度余計にお時間をいただきます。
          </P>
        </FlexItem>
      </Flex>
      <Flex column={size !== 'lg'} justifyAround={size === 'lg'}>
        <Exam
          size={size}
          title="超音波検査"
          imgSrc={echoPanc}
          content="腹部超音波（エコー）検査は合併症がなく、CTよりも細かく観察することが可能であり、膵臓の検査では非常に大切な検査となります。
      その超音波検査を、じっくり丁寧に行うのが当院の特徴。「膵臓」と決めつけず、他の臓器（肝臓、胆嚢、総胆管、脾臓、腎臓）や下腹部もしっかりと観察いたします。
      ただし、膵臓は非常に奥まったところにあるため、超音波検査で膵臓をくまなく観察することは非常に難しいのが実情。観察しづらい部分については、MRCPで補完します。"
        />
        <Exam
          size={size}
          title="血液検査"
          imgSrc={blood}
          content="アミラーゼ（AMY）は広く行われている検査ですが、高値だからといって直ちに膵炎ということではありません。
        体質で高くなってしまう方も少なくなく、そういった可能性も含めてしっかりと検査を行います。
        また、アミラーゼ（AMY）は低いのにリパーゼ、トリプシンといった他の膵関連の値が高い、という方も少なくありません。
        当院の膵臓ドックでは、それらに加えて、腫瘍マーカーのCA19-9、エラスターゼ１を加えて検査をいたします。"
        />
        <Exam
          size={size}
          title="MRCP"
          imgSrc={mrcp}
          content="MRCPは膵臓・胆道系に特化したMRIの撮影法です。
        その特徴は、膵臓をくまなく観察でき、奇形や膵炎、膵癌も発見できること。（MRCPの膵癌に対する感度は95%、特異度は82%と報告されています※）
        しかし保険診療では、何か異常がないとMRCPを行うことができません。
        当院の膵臓ドックでは、そのMRCPを近隣の専門施設で受けていただくことができます。"
        />
      </Flex>
      <Flex
        center
        css={`
          margin: 10px;
        `}
      >
        <P
          css={`
            font-size: 14px;
          `}
        >
          ※[LOPEZ HANNINEN E, AMTHAUER H, HOSTEN N, RICKE J, BOHMIG M, LANGREHR J, HINTZE R, NEUHAUS
          P, WIEDENMANN B, ROSEWICZ S, FELIX R. PROSPECTIVE EVALUATION OF PANCREATIC
          TUMORS：ACCURACY OF MR IMAGING WITH MR CHOLANGIOPANCREATOGRAPHY AND MR ANGIOGRAPHY.
          RADIOLOGY 2002；224：34─41.]
        </P>
      </Flex>
    </SubLayout>
  );
};

export default PancreasExamination;
